import type { RecordStatus } from 'o365.pwa.types.ts';

declare module 'o365.pwa.declaration.sw.O365OfflineDataRecord.d.ts' {
    export interface IO365OfflineDataRecord {
        PrimKey: string;
        Created: string;
        CreatedBy: string;
        CreatedBy_ID: string;
        Updated: string;
        UpdatedBy: string;
        UpdatedBy_ID: string;
        Status: RecordStatus;
        JsonData: string;
        OrgUnit: string;
        OrgUnit_ID: number;
        Date: Date;
        UpdatedFields: string;
        OriginalValues: string;
        JsonDataVersion: number | null;
        ExternalRef: string;
        DeviceRef?: string;
        [key: string]: unknown;
    }

    export interface IO365OfflineDataFileRecord extends IO365OfflineDataRecord {
        FileName: string;
        FileRef: string;
        FileSize: number;
        Extension: string;
    }

    export interface IO365FlatOfflineDataRecord {
        O365_PrimKey: string;
        O365_Created: string;
        O365_CreatedBy: string;
        O365_CreatedBy_ID: number | null;
        O365_Updated: string;
        O365_UpdatedBy: string;
        O365_UpdatedBy_ID: number | null;
        O365_Status: RecordStatus;
        O365_UpdatedFields: string;
        O365_OriginalValues: string;
        O365_JsonDataVersion: number | null;
        O365_ExternalRef: string | null;
        O365_DeviceRef?: string;
        [key: string]: unknown;
    }

    export interface IO365FlatOfflineDataFileRecord extends IO365FlatOfflineDataRecord {
        FileName: string;
        FileRef: string;
        FileSize: number;
        Extension: string;
    }

    export function restructureRecordForOfflineDB(record: IO365OfflineDataRecord): IO365FlatOfflineDataRecord | null;
    export function restructureRecordForOfflineDB(record: IO365OfflineDataFileRecord): IO365FlatOfflineDataFileRecord | null;

    export function restructureRecordForOnlineDB(record: IO365FlatOfflineDataRecord): IO365OfflineDataRecord;
    export function restructureRecordForOnlineDB(record: IO365FlatOfflineDataFileRecord): IO365OfflineDataFileRecord;
}
